/* Styles for the popup overlay */
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it appears above other elements */
}

/* Styles for the popup content box */
.popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 300px; /* Set a width for the popup content */
  max-width: 90%;
  text-align: center;
}

/* Styles for the input field */
.popup-content input[type="text"] {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Styles for the buttons */
.popup-content button {
  padding: 8px 12px;
  margin: 5px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.popup-content button:first-of-type {
  background-color: #007bff; /* Save button color */
  color: white;
}

.popup-content button:last-of-type {
  background-color: #ccc; /* Cancel button color */
  color: black;
}

.popup-content button:hover {
  opacity: 0.9; /* Slight fade effect on hover */
}