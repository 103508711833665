.numbersHeader {
  display:flex;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 40px;
}

.numbersHeaderHeading {
  text-transform: uppercase;
  font-size: 14px;
  margin: 0px;
}

.numbersHeaderNumber {
  font-size: 20px;
}