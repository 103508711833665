:root {
  --font-family-heading: 'Poppins', sans-serif;
  --font-family-paragraph: 'Montserrat', sans-serif;
  --font-family-monospace: monospace;
  --shadow-color: #00be00;
  --lightgray-color: #e5e5e5;
  --text-color: black;
}

body {
  background: var(--base-color);
  font-family: var(--font-family-paragraph);
  color: var(--text-color);
  margin: 0;
  overflow-x: hidden;
}


h1 {
  font-family: 'Poiret One', cursive;
  font-size: 3rem;
  /*text-transform: uppercase;*/
  color: var(--shadow-color);
  /*text-shadow: -2px 0 black, 0 2px black, 2px 0 black, 0 -2px black;*/
}

h2 {
  font-family: 'Poiret One', cursive;
  font-size: 2.4rem;
}

a {
  color: black;
}

.flex-box-container-2-columns {
  display: flex; 
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  grid-gap: 50px;
}

.flex-box {
  flex-grow: 1;
  margin: 0;
  width: 450px;
}

.gray-background-box {
  background: #ededed;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.1rem;
  padding-bottom: 1rem;
  opacity: 0.94;
}

.text-decoration-none {
  text-decoration: none;
}

.color-black {
  color: black;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-pointer-important {
  cursor: pointer !important;
}

.data-table {
  width: 100%;
}

.text-align-right {
  text-align: right;
}

.text-align-left {
  text-align: left;
}

.uppercase {
  text-transform: uppercase;
}

.word-break-break-all {
  word-break: break-all;
}


/* ************************************************************************ */
/* HEADER */
/* ************************************************************************ */
.header {
  position: fixed;
  top: 0px;
  margin-top: 0px;
  text-align: right;
  width: 100%;
  overflow: hidden;
  background-color: black;
  z-index: 1;
  border-bottom: 1px solid var(--shadow-color);
}

.header a {
  float: left;
  color: lightgray;
  text-align: center;
  padding: 12px;
  text-decoration: none;
  font-size: 14px; 
  line-height: 25px;
}

@media (min-width: 800px) {
  .header a {
      font-size: 18px;
  }
}

.header a:hover {
  color: var(--shadow-color);
}

.header a.logo {
  font-family: 'Poiret One', cursive;
  font-size: 1.1rem;
  color: var(--shadow-color);
  text-shadow: -2px 0 black, 0 2px black, 2px 0 black, 0 -2px black;
}

@media (min-width: 800px) {
  .header a.logo {
      font-size: 2rem;
  }
}

.header-right {
  float: right;
}

.gray-background {
  background-color: #ededed;
}

/* ******************************** */
/* End header */
/* ******************************** */



/* ******************************** */
/* Main content */
/* ******************************** */

main {
  padding-top: 100px;
}

.center-container {
  max-width: 1020px;
  margin-left: auto;
  margin-right: auto;
}

section {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
  max-width: 1000px;
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (min-width: 800px) {
  section {
      padding:0;
  }
}

.button {
  background-color: black;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1.5rem;
  box-shadow: 2px 2px var(--shadow-color);
}

@media (min-width: 800px) {
  .button {
      font-size: 2rem;
  }
}

.button:hover {
  cursor: pointer;
}

.button:disabled {
  background-color: gray;
  cursor: default;
}

/* ******************************** */
/* End Main content */
/* ******************************** */


/* ******************************** */
/* Sites */
/* ******************************** */
.site-box {
  border: 1px solid black;
  padding: 10px;
  max-width: 450px;
}

/* ******************************** */
/* End Sites */
/* ******************************** */


/* ***************** */
/* AnalyticsPage Start */
/* ***************** */
.filters-header {
  display:flex;
  justify-content: left;
  gap: 10px;
  padding-top: 10px;
  padding-bottom: 20px;
}

.filters-tag {
  border: 1px solid lightgray;
  padding: 5px;
  padding-left: 8px;
  padding-right: 8px;
}

.no-data-box {
padding: 20px;
  border: solid 1px red;
  margin: 20px;
  background-color: #ff767666;
}

.code-snippet {
    background: #f4f4f4;
    border: 1px solid #ddd;
    border-left: 3px solid #f36d33;
    color: #666;
    page-break-inside: avoid;
    font-family: monospace;
    font-size: 15px;
    line-height: 1.6;
    margin-bottom: 1.6em;
    margin-top: 1.6em;
    max-width: 100%;
    overflow: auto;
    padding: 1em 1.5em;
    display: block;
    word-wrap: break-word;
}

/* ***************** */
/* AnalyticsPage End */
/* ***************** */








/** This is to replace the call to google fonts with local static assets */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('./fonts/poppins-normal-400.ttf') format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('./fonts/poppins-normal-700.ttf') format('truetype');
}
/** end */


